import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Akali = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Akali | HB Privileged',
      title: 'AKALI',
      subtitle: 'TOUR OVERVIEW',
      text: "Get on board the 56 ft, 2 stories boat Akali, at the Marina in Vallarta, just a few minutes from our hotel. The most luxurious and with the best service. They do 2 tours a day. One for snorkeling, kayacking or paddleboarding at the Los Arcos Natural Water Park, and another one to enjoy the sunset. Both tours include alcoholic and non alcoholic premium beverages, food, and personalized attention by the staff. Weather you´re in a romantic, family or party plan, the Akali will make your vacation memorable! Ask for her with your Concierge at the desk located in front of the reception desk.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Akali | HB Privileged',
      title: 'AKALI',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Aborda el catamarán Akali de 56 pies de eslora y dos pisos que se encuentra en la Marina Vallarta a unos minutos de nuestro hotel. El más lujoso disponible y con el mejor servicio. Hacen dos tours al día, uno para snorkelear, hacer kayac, o paddleboarding en el Parque Acuático Natural de Los Arcos y otro para disfrutar del atardecer. Los dos tours incluyen bebidas alcohólicas y no alcohólicas premium, alimentos, y la atención personalizada del staff. Ya sea que vayas en plan romántico, familiar o de fiesta, el Akali hará que tus vacaciones sean memorables! Pregúntale por él a tu Concierge en el escritorio frente a recepción.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/akali1.jpg`,
    `${BASE_URL}/tours/akali2.jpg`,
    `${BASE_URL}/tours/akali3.jpg`,
    `${BASE_URL}/tours/akali4.jpg`,
    `${BASE_URL}/tours/akali5.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/akali.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Akali;